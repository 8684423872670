import CustomerFormContainer from "./CustomerFormContainer";
import Navbar from "./Navbar";

const Customer = ({
	logo,
	typeOfDemo,
	view,
	handleViewChange,
	feedbackIsAdded,
	variables,
	setFeedbackIsAdded,
}) => {
	return (
		<div className="customer-container">
			<Navbar
				logo={logo}
				typeOfDemo={typeOfDemo}
				view={view}
				handleViewChange={handleViewChange}
			/>
			<div className="customer-wrapper">
				<CustomerFormContainer
					variables={variables}
					setFeedbackIsAdded={setFeedbackIsAdded}
				/>
			</div>
		</div>
	);
};
 
export default Customer;