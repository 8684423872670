import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as EmailIcon } from "../assets/mail.svg";

const NoResults = () => {
	return (
		<div className="no-results">
			<div className="no-results__icon">
				<InfoIcon />
			</div>
			<div className="no-results__text">
				<div className="no-results__text-title">
					AI Assistant couldn't find what you are searching for
				</div>
				<div className="no-results__text-subtitle">
					Please contact our support directly using this email to reach out to
					our team.
				</div>
				<div className="no-results__email">
					<EmailIcon />
					<a href="mailto:support@innential.com">support@innential.com</a>
				</div>
			</div>
		</div>
	);
};

export default NoResults;
