import React, { useState, useEffect } from "react";
import { ReactComponent as WarningIcon } from "../assets/alert-triangle.svg";
import { ReactComponent as CloseIcon } from "../assets/close.svg";

const Alert = ({ message, duration, close }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (message) {
			setShow(true);
			const timer = setTimeout(() => {
				setShow(false);
				close();
			}, duration);
			return () => clearTimeout(timer);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message, duration]);

	if (!message) return null;

	return (
		<div className={`alert ${show ? "show" : ""}`}>
    <WarningIcon />
			<p>{message}</p>
      <button onClick={close}><CloseIcon/></button>
		</div>
	);
};

export default Alert;