import { ReactComponent as WarningIcon } from "../assets/alert-triangle.svg";
const Warning = ({ warningText }) => {
	const sentences = warningText.split("\n");
	const note = sentences.pop();
	const warningMessage = sentences.join("\n");
	return (
		<div className="feedback-warning">
			<div style={{ paddingTop: "3px", paddingRight: "8px" }}>
				<WarningIcon />
			</div>

			<div>
				{warningMessage}
				<div style={{ color: "hsla(11,80%,45%,1)", paddingTop: "6px" }}>
					{note}
				</div>
			</div>
		</div>
	);
};

export default Warning;
