const StepsComponent = ({ inputText, language }) => {
	// Function to format HTML content
	function formatContent(html) {
		// Adjusted regex to specifically target <b> and </b> tags
		const boldRegex = /<b>(.*?)<\/b>/g;
		html = html.replace(boldRegex, "$1"); // Replace <b>...</b> with just the content

		// Keep the existing logic for handling bold and italic text outside of <b> tags
		const boldItalicRegex = /(\*\*|__)(.*?)\1/g;
		const italicRegex = /(\*|_)(.*?)\1/g;
		html = html.replace(boldItalicRegex, "<b>$2</b>");
		html = html.replace(italicRegex, "<i>$2</i>");

		// Handling warnings
		const warningRegex = /<p>(Warning:|Warnung:|Achtung:)(.*?)<\/p>/g;
		html = html.replace(warningRegex, (match, p1, p2) => {
			if (language !== "German" && p1.startsWith("Warnung: Es")) {
				return "";
			}
			return `<p><span role="img" aria-label="warning" style="margin-right: 5px;">⚠️</span> ${p1}${p2}</p>`;
		});

		// Handling steps
		const stepRegex =
			/<p>(Step|Schritt|Paso|Krok|Étape) (\d+)(?:\..*?)? *:?\s*([^0-9].*)<\/p>/gi;
		html = html.replace(stepRegex, (match, p1, p2, p3) => {
			return `
                <div class="step">
                    <div style="
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: #E3E9F2;
                        color: #000;
                        text-align: center;
                        line-height: 26px;
                        margin-right: 5px;
                        font-size: 12px;
                        font-weight: 700;
                    ">${p2}</div>
                    <span>${p3}</span>
                </div>
            `;
		});

		return html;
	}

	// Format the entire input text content
	const formattedContent = formatContent(inputText);

	return <div dangerouslySetInnerHTML={{ __html: formattedContent }} />;
};

export default StepsComponent;
