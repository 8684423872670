export function getBrightness(color) {
	const rgb = parseInt(color.replace("#", ""), 16); // convert rrggbb to decimal
	const r = (rgb >> 16) & 0xff; // extract red
	const g = (rgb >> 8) & 0xff; // extract green
	const b = (rgb >> 0) & 0xff; // extract blue

	// Calculate brightness according to the formula: (299*R + 587*G + 114*B) / 1000
	return (299 * r + 587 * g + 114 * b) / 1000;
}

// Function to convert audio blob to base64 encoded string
export function audioBlobToBase64 (blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const arrayBuffer = reader.result;
			const base64Audio = btoa(
				new Uint8Array(arrayBuffer).reduce(
					(data, byte) => data + String.fromCharCode(byte),
					""
				)
			);
			resolve(base64Audio);
		};
		reader.onerror = reject;
		reader.readAsArrayBuffer(blob);
	});
};

// Helper function to replace underscores with spaces in labels
export function formatDeviceName(name) {
	return name.replace(/_/g, " ");
}
