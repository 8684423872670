import { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom";

import "./App.css";
import axios from "axios";
import Installer from "./components/Installer";
import Customer from "./components/Customer";
import Loading from "./components/Loading";
import Logo from "./assets/logo-ver2.svg";

const defaultVars = {
	name: "Innential",
	mainColor: "#8745EF",
	logo: Logo,
	slug: "Innential",
	mainLanguage: "English",
	devices: [
		{
			deviceName: "Inverter_1",
			exampleQuestions: [
				"What types of courses pliz offers on machinery safety and automation?",
				"What are the different types of courses pliz offers on machinery safety and automation?",
			],
		},
		{
			deviceName: "Battery",
			exampleQuestions: ["What types of batteries are safe to use?"],
		},
		{ deviceName: "Solar Panel", exampleQuestions: [] },
	],
	deviceTypes: [
		{
			type: "Heat Pump",
			manufacturers: [
				{
					manufacturer: "SMA",
					companyName: "SMA-1",
					devices: [
						{
							deviceName: "Model30",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model4",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model5",
							exampleQuestions: [],
						},
						{
							deviceName: "Model12",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model11",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model8",
							exampleQuestions: [],
						},
						{
							deviceName: "Model9",
							exampleQuestions: [],
						},
						{
							deviceName: "Model10",
							exampleQuestions: [],
						},
					],
				},
				{
					manufacturer: "Zolar",
					companyName: "Zolar-1",
					devices: [
						{
							deviceName: "Model1",
							exampleQuestions: [
								"What types of courses pliz offers on machinery safety and automation?",
							],
						},
						{
							deviceName: "Model3",
							exampleQuestions: [
								"What are the different types of courses pliz offers on machinery safety and automation?",
							],
						},
					],
				},
			],
		},
		{
			type: "Solar Panel",
			manufacturers: [
				{
					manufacturer: "SMA",
					companyName: "SMA-12",
					devices: [
						{
							deviceName: "Model4",
							exampleQuestions: [],
						},
						{
							deviceName: "Model5",
							exampleQuestions: [],
						},
					],
				},
			],
		},
	],
	type: "Manufacturer",
	// type: "Distributor",
	aiTrained: false,
	typeOfDemo: "Both",
	newAi: false,
};

function App() {
	const [variables, setVariables] = useState(defaultVars);
	const [isLoading, setIsLoading] = useState(true);
	const [feedbackIsAdded, setFeedbackIsAdded] = useState(false);
	const [typeOfDemo, setTypeOfDemo] = useState("Both");
	const [view, setView] = useState("Installer");

	const location = useLocation();

	const navigate = useNavigate();

	const handleNavigation = (path) => {
		navigate(path);
	};

	 const handleViewChange = (e) => {
			const newView = e.target.checked ? "Customer" : "Installer";
			setView(newView);
			handleNavigation(newView === "Customer" ? "/customer" : "/installer");
		};

	useEffect(() => {
		setIsLoading(true);
		const instanceId = process.env.REACT_APP_INSTANCE_ID;
		const environment = process.env.REACT_APP_ENV;

		// Check if instanceId is not available
		if (!instanceId) {
			setVariables(defaultVars);
			setIsLoading(false);
			return;
		}

		let api;
		switch (environment) {
			case "development":
				api = "http://localhost:9000/instance";
				break;
			case "staging":
				api = "https://admin-api.service-assistant-staging.com/instance";
				break;
			case "production":
				api = "https://admin-api.service-assistant.ai/instance";
				break;
			default:
				console.warn(`Unsupported environment: ${environment}`);
				setIsLoading(false); // Set loading state to false if environment is unsupported
				return; // Exit useEffect early
		}

		// Ensure api is defined before proceeding
		if (!api) {
			console.error("API URL could not be determined.");
			setIsLoading(false);
			return;
		}

		const sendData = {
			instanceId: instanceId,
		};

		axios
			.get(api, {
				params: sendData,
			})
			.then((response) => {
				setVariables(response.data);
				setTypeOfDemo(response.data.typeOfDemo || "Both");
				const pageLinks = document.getElementsByTagName("link");
				const pageLink = Array.from(pageLinks).find((link) => {
					return link.rel === "icon";
				});
				if (pageLink && response?.data?.favIcon) {
					pageLink.href = response.data.favIcon;
				}
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error:", error);
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		const currentPath = location.pathname;
		if (currentPath === "/customer") {
			setView("Customer");
		} else if (currentPath === "/installer") {
			setView("Installer");
		}
	}, [location.pathname]);
	
	 useEffect(() => {
			// Redirect to a valid route based on typeOfDemo
			if (typeOfDemo === "Installer" && location.pathname !== "/") {
				window.history.replaceState(null, "", "/");
			} else if (typeOfDemo === "Customer" && location.pathname !== "/") {
				window.history.replaceState(null, "", "/");
			} else if (typeOfDemo === "Both" && location.pathname === "/") {
				window.history.replaceState(null, "", "/installer");
			}
		}, [typeOfDemo, location.pathname]);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<Routes>
			{typeOfDemo === "Installer" && (
				<Route
					path="/"
					element={
						<Installer
							logo={variables.logo}
							typeOfDemo={typeOfDemo}
							view={view}
							handleViewChange={handleViewChange}
							feedbackIsAdded={feedbackIsAdded}
							variables={variables}
							setFeedbackIsAdded={setFeedbackIsAdded}
						/>
					}
				/>
			)}
			{typeOfDemo === "Customer" && (
				<Route
					path="/"
					element={
						<Customer
							logo={variables.logo}
							typeOfDemo={typeOfDemo}
							view={view}
							handleViewChange={handleViewChange}
							feedbackIsAdded={feedbackIsAdded}
							variables={variables}
							setFeedbackIsAdded={setFeedbackIsAdded}
						/>
					}
				/>
			)}
			{typeOfDemo === "Both" && (
				<>
					<Route path="/" element={<Navigate to="/installer" />} />
					<Route
						path="/installer"
						element={
							<Installer
								logo={variables.logo}
								typeOfDemo={typeOfDemo}
								view={view}
								handleViewChange={handleViewChange}
								feedbackIsAdded={feedbackIsAdded}
								variables={variables}
								setFeedbackIsAdded={setFeedbackIsAdded}
							/>
						}
					/>
					<Route
						path="/customer"
						element={
							<Customer
								logo={variables.logo}
								typeOfDemo={typeOfDemo}
								view={view}
								handleViewChange={handleViewChange}
								feedbackIsAdded={feedbackIsAdded}
								variables={variables}
								setFeedbackIsAdded={setFeedbackIsAdded}
							/>
						}
					/>
				</>
			)}
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
}

const AppWrapper = () => (
	<Router>
		<App />
	</Router>
);

export default AppWrapper;
