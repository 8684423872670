import { Tooltip } from "react-tooltip";
import { ReactComponent as CalibrationIcon } from "../assets/calibration.svg";
const Banner = ({ feedbackIsAdded }) => {
	    const adjustTooltipPosition = () => {
				const bannerLeft = document.querySelector(".banner-left");
				const tooltip = document.getElementById("bannerTooltip");
				if (bannerLeft && tooltip) {
					const bannerLeftRect = bannerLeft.getBoundingClientRect();
					const tooltipRect = tooltip.getBoundingClientRect();
					const margin = 16; // 1rem in pixels

					let tooltipLeft =
						bannerLeftRect.left +
						bannerLeftRect.width / 2 -
						tooltipRect.width / 2;

					// Ensure tooltip stays within the viewport with at least 1rem margin
					const viewportWidth = window.innerWidth;
					if (tooltipLeft < margin) {
						tooltipLeft = margin;
					} else if (tooltipLeft + tooltipRect.width > viewportWidth - margin) {
						tooltipLeft = viewportWidth - margin - tooltipRect.width;
					}

					tooltip.style.left = `${tooltipLeft}px`;
				}
			};
	return (
		<div id="gradient" data-tooltip-id="bannerTooltip">
			<div className="banner">
				<div className="banner-left">
					<CalibrationIcon />
					<span>System in calibration</span>
				</div>
				<div className="banner-right">
					{feedbackIsAdded
						? "Thank you for your feedback!"
						: "Our AI is still learning and optimizing..."}
				</div>
			</div>
			<Tooltip
				id="bannerTooltip"
				className="banner-tooltip"
				place="bottom"
				effect="solid"
				positionStrategy="fixed"
				afterShow={adjustTooltipPosition}
			>
				<div>
					Our AI is still learning and optimizing to provide the best possible
					answers. You may notice improvements in response quality over time as
					it adapts and calibrates based on your feedback and real usage.
				</div>
			</Tooltip>
		</div>
	);
};

export default Banner;
