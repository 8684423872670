import Navbar from "./Navbar";
import Banner from "./Banner";
import FormContainer from "./FormContainer";

const Installer = ({
	logo,
	typeOfDemo,
	view,
	handleViewChange,
	feedbackIsAdded,
	variables,
	setFeedbackIsAdded,
}) => {
	return (
		<div className="app">
			<Navbar
				logo={logo}
				typeOfDemo={typeOfDemo}
				view={view}
				handleViewChange={handleViewChange}
			/>
			<Banner feedbackIsAdded={feedbackIsAdded} />
			<div className="main-container">
				<FormContainer
					variables={variables}
					setFeedbackIsAdded={setFeedbackIsAdded}
				/>
			</div>
		</div>
	);
};

export default Installer;
