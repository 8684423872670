import React, { useState, useEffect } from "react";
import {ReactComponent as CloseIcon} from "../assets/close.svg";
import {ReactComponent as SlidersIcon} from "../assets/sliders.svg";

const Switch = ({ view, handleViewChange }) => {
	const [isSwitchVisible, setIsSwitchVisible] = useState(false);
	const [isMobileView, setIsMobileView] = useState(window.innerWidth < 460);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth < 460);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleOptionClick = () => {
		setIsSwitchVisible(true);
	};

	const handleCloseClick = () => {
		setIsSwitchVisible(false);
	};

	return (
		<div style={{ position: "relative", marginLeft: 'auto' }}>
			{isMobileView ? (
				isSwitchVisible ? (
					<div className="switch-container visible">
						<p
							className={
								view === "Installer"
									? "installer-text checked"
									: "installer-text"
							}
						>
							Installer
						</p>
						<label className="switch">
							<input
								type="checkbox"
								id="modeToggle"
								onChange={handleViewChange}
								checked={view === "Customer"}
							/>
							<span className="slider"></span>
						</label>
						<p
							className={
								view === "Installer" ? "customer-text" : "customer-text checked"
							}
						>
							Customer
						</p>
						<button className="close-button" onClick={handleCloseClick}>
							<CloseIcon />
						</button>
					</div>
				) : (
					<button className="option-button" onClick={handleOptionClick}>
						<SlidersIcon />
					</button>
				)
			) : (
				<div className="switch-container">
					<p
						className={
							view === "Installer" ? "installer-text checked" : "installer-text"
						}
					>
						Installer
					</p>
					<label className="switch">
						<input
							type="checkbox"
							id="modeToggle"
							onChange={handleViewChange}
							checked={view === "Customer"}
						/>
						<span className="slider"></span>
					</label>
					<p
						className={
							view === "Installer" ? "customer-text" : "customer-text checked"
						}
					>
						Customer
					</p>
				</div>
			)}
		</div>
	);
};

export default Switch;
