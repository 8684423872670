import Switch from "./Switch";
import Logo from "../assets/logo-ver2.svg";

const Navbar = ({ logo, typeOfDemo, view, handleViewChange }) => {
	return (
		<div className="navbar">
			<div className="navbar-logo">
				{view === "Installer" ? (
					<img
						src={logo || Logo}
						alt="Logo"
						//  width={"136px"} height={"70px"}
					/>
				) : null}

				{typeOfDemo === "Both" ? (
					<Switch view={view} handleViewChange={handleViewChange} />
				) : null}
			</div>
		</div>
	);
};

export default Navbar;
