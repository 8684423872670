const ProgressBar = ({ progress, status, color }) => {
	return (
		<div>
			<div className="progress-text">{progress}%</div>
			<div className="progress-bar__background">
				<div className="progress-bar" style={{ width: `${progress}%`, backgroundColor: color }}></div>
			</div>
			<div className="status-text">{status}</div>
		</div>
	);
};

export default ProgressBar;
